export const state = () => ({
    bannerList: [],
    showPerTaskLimitReachedError: false,
    upgradingMidConversion: false
})
export const mutations = {
    addBanner(state, item) {
        state.bannerList.push(item);
    },
    setUpgradingMidConversion(state, value) {
        state.upgradingMidConversion = value
        value === true
            ? window.localStorage.setItem('upgradingMidConversion', true)
            : setTimeout(() => { window.localStorage.removeItem('upgradingMidConversion') }, 10000)
    },
    clearAllBanners(state, except) {
        if (except) {
            const bannerList = state.bannerList.filter(
                (banner) => banner.id === except
            );
            state.bannerList = bannerList;
        } else {
            state.bannerList = [];
        }
        state.showPerTaskLimitReachedError = false;
    },
    clearBanner(state, id) {
        const bannerList = state.bannerList.filter(
            (banner) => banner.id !== id
        );
        state.bannerList = bannerList;
    },
    setBanner(state, item) {
        state.banner = item;
    },
    showLimitReachedError(state) {
        if (
            state.bannerList.findIndex(
                (banner) => banner.id === "LimitReachedError"
            ) == -1
        ) {
            /**
             * Free = 'free'
             * Basic || Standard || Pro = 'monthly'
             * 24-h Pass = ''
             */
            const subscription = $nuxt.$store.state.subscription.name.replace('Web', '').trim();
            const text = $nuxt
                .$t("limit_reached_desc")
                .replace("MINUTES", $nuxt.$store.state.subscription.minutes)
                .replace("NAME",
                    !subscription || subscription === 'Free'
                        ? 'free'
                        : ['Basic', 'Standard', 'Pro'].some(name => subscription.toLowerCase().includes(name.toLowerCase()))
                            ? 'monthly'
                            : subscription === '24-h Pass'
                                ? ''
                                : ''
                );
            state.bannerList.push({
                id: "LimitReachedError",
                type: "error",
                action: "upgrade",
                title: $nuxt.$t("limit_reached"),
                text,
                ga4Event: {
                    name: "out_of_web_minutes_banner",
                    page: $nuxt.$store.state.info.slug,
                    content: "upgrade-banner",
                },
            });
        }
    },
    showFreeLimitPerFileWarn(state) {
        if (
            state.bannerList.findIndex(
                (banner) => banner.id === "FreeLimitPerFileWarn"
            ) == -1
        )
            state.bannerList.push({
                id: "FreeLimitPerFileWarn",
                type: "error",
                action: "upgrade",
                title: $nuxt.$t("upgrade_recommended"),
                text: $nuxt
                    .$t("upgrade_recommended_desc")
                    .replace("LIMIT", $nuxt.$store.state.subscription.perTaskLimit)
                    .replace("NAME", $nuxt.$store.state.subscription.name),
                ga4Event: {
                    name: "free_limit_per_file_warn_banner",
                    page: $nuxt.$store.state.info.slug,
                    content: "upgrade-banner",
                },
                toPro: true,
            });
    },
    removeFreeLimitPerFileWarn(state) {
        const indexToRemove = state.bannerList.findIndex(
            (banner) => banner.id === "FreeLimitPerFileWarn"
        );
        if (indexToRemove !== -1) {
            state.bannerList.splice(indexToRemove, 1);
        }
    },
    displayTooManyFileBanner(state, value = true) {
        if (
            state.bannerList.findIndex(
                (banner) => banner.id === "TooManyFileBanner"
            ) == -1
        )
            state.bannerList.push({
                id: "TooManyFileBanner",
                type: "error",
                action: "upgrade",
                title: $nuxt.$t("convert_more_concurrent_title"),
                text: $nuxt
                    .$t("convert_more_concurrent_desc")
                    .replace("NAME", $nuxt.$store.state.subscription.name || "Guest")
                    .replace(
                        "MAX",
                        $nuxt.$store.state.subscription.plan.maxConcurrentConversions || 5
                    ),
                ga4Event: {
                    name: "max_concurrent_conversions_banner",
                    page: $nuxt.$store.state.info.slug,
                    content: "upgrade-banner",
                },
            });
    },
    showPerTaskLimitReachedError(state) {
        state.showPerTaskLimitReachedError = true
        if (
            state.bannerList.findIndex(
                (banner) => banner.id === "PerTaskLimitReachedError"
            ) == -1
        ) {
            state.bannerList.push({
                id: "PerTaskLimitReachedError",
                type: "error",
                action: "upgrade",
                title: '',
                text: $nuxt
                    .$t("limit_reached_free_desc")
                    .replace("NAME", $nuxt.$store.state.subscription.name || "Guest")
                    .replace("LIMIT", $nuxt.$store.state.subscription.perTaskLimit),
                ga4Event: {
                    name: "free_limit_per_file_banner",
                    page: $nuxt.$store.state.info.slug,
                    content: "upgrade-banner",
                },
            });
        }
    },
    showMaxFileSizeError(state, { size, text }) {
        if (
            state.bannerList.findIndex(
                (banner) => banner.id === "PerTaskLimitReachedError"
            ) == -1
        ) {
            const ga4Event = {
                event: "file_size_too_big_banner",
                page: `${$nuxt.$store.state.info.slug}-upload`,
                content: "upgrade-banner",
            }
            state.bannerList.push({
                id: "MaxFileSizeError",
                type: "error",
                action: false,
                title: $nuxt.$t("file_size_too_big").replace("SIZE", `(${size})`),
                text: text,
                ga4Event,
            });
            this.$gtm.push(ga4Event);
        }
    },
    showMergeLimitExceededError(state) {
        if (
            state.bannerList.findIndex(
                (banner) => banner.id === "MergeLimitExceededError"
            ) == -1
        )
            state.bannerList.push({
                id: "MergeLimitExceededError",
                type: "error",
                action: "upgrade",
                title: $nuxt.$t("merge_limit_exceeded_title"),
                text: $nuxt
                    .$t("merge_limit_exceeded_text")
                    .replace("LIMIT", `(${$nuxt.$store.state.subscription?.maxMergeLimit})`),
                ga4Event: {
                    name: "free_merge_limit_banner",
                    page: $nuxt.$store.state.info.slug,
                    content: "upgrade-banner",
                },
                redirectOnTheSameTab: true
            });
    },
    hideMergeLimitExceededError() {
        this.commit('banner/clearBanner', 'MergeLimitExceededError')
    },
    hideLimitReachedError(state) {
    },
    hidePerTaskLimitReachedError(state) {
        state.showPerTaskLimitReachedError = false;
    }
}
export const actions = {
    setShowFreeLimitPerFileWarn({ commit, state }, file) {
        const { list, subscription } = $nuxt.$store.state;
        const maxFreeUserPerFileLimit = 524288000; // 500MB in bytes
        const accountType = $nuxt.$auth.loggedIn ? subscription.name : "Guest";

        // don't display 'per file limit' banner for pro users
        if (!["Guest", "Free"].includes(accountType)) return;

        // if this action is dispatched for a single file, no need to go through all files.
        // just check the current file
        if (file) {
            if (file.total > maxFreeUserPerFileLimit) {
                commit("showFreeLimitPerFileWarn");
            }
            return;
        }

        // if there is a file that has more than 500MB,
        // then display 'per file limit' banner
        for (const item of list) {
            if (item.total > maxFreeUserPerFileLimit) {
                commit("showFreeLimitPerFileWarn");
                return;
            }
        }

        const bannerIndex = state.bannerList.findIndex(
            (banner) => banner.id === "FreeLimitPerFileWarn"
        );
        if (bannerIndex != -1) state.bannerList.splice(bannerIndex, 1);
    },
}
export const strict = false
