/* eslint-disable no-undef */
import {
  OUT_OF_CONVERSION_MINUTES_PER_TASK,
  UPLOAD_TIMEOUT,
  ENGINE_TIMEOUT,
  OUT_OF_CONVERSION_MINUTES,
  USER_DELETED_TASK,
  DRM_PROTECTED_FILE,
} from "~/fc/Constants/task-error-code";
import {
  STATUS_UPLOADING,
  STATUS_DONE,
  STATUS_ERROR,
  STATUS_READY,
  STATUS_CONVERTING,
  STATUS_LABELS,
  STATUS_EXPORTING,
  STATUS_IMPORTING,
  STATUS_LIMIT_REACH_ERROR,
  STATUS_TASK_DELETED,
  STATUS_REUPLOADING,
  getEventOrderValue,
  STATUS_SERVER_ERROR,
  STATUS_UPLOAD_TIMEOUT,
  STATUS_CREATING_JOB,
  STATUS_RETRYING,
  STATUS_DRM_PROTECTED_FILE_ERROR,
} from "../fc/Constants/job-status";

const TASK_PROCESSING = "processing";
const TASK_COMPLETED = "completed";
const TASK_FAILED = "failed";

const getOperationName = (op) => {
  let name = STATUS_LABELS[STATUS_READY];
  if (op) {
    op = op.toLowerCase();
    if (op.includes("import")) {
      name = STATUS_LABELS[STATUS_IMPORTING];
    } else if (op.includes("export")) {
      name = STATUS_LABELS[STATUS_EXPORTING];
    } else {
      name = op + "ing";
    }
  }
  return name;
};
export const state = () => ({
  bulkItemJobId: "",
  jobs: {},
  tasks: {},
});

export const getters = {
  getServerCreatedJobs(state) {
    return Object.keys(state.jobs).reduce(function (r, e) {
      if (state.jobs[e]?.step != STATUS_CREATING_JOB) r[e] = state.jobs[e];
      return r;
    }, {});
  },
};

export const mutations = {
  clear(state) {
    state.bulkItemJobId = "";
    state.jobs = {};
    state.tasks = {};
  },
  removeJob(store, jobID) {
    const jobs = {
      ...store.jobs,
    };
    if (!jobs[jobID]) {
      return;
    }
    // eslint-disable-next-line no-unsafe-optional-chaining
    for (const taskId of jobs[jobID]?.tasks) {
      $nuxt.$socket.emit("unsubscribe", `task.${taskId}`);
    }
    delete jobs[jobID];
    store.jobs = jobs;
  },
  setJob(store, job) {
    const jobs = {
      ...store.jobs,
    };
    jobs[job.id] = {
      type: job.type,
      status: job.status ? job.status : STATUS_READY,
      statusText: job.statusText ? job.statusText : STATUS_LABELS[STATUS_READY],
      statusTitle: job.statusTitle ? job.statusTitle : "",
      output: "",
      tasks: job.tasks ? job.tasks.map((task) => task.id) : [],
      step: job.step ? job.step : null,
      isBulkItemJob: !!job.isBulkItemJob,
      totalUploadedFile: 0,
      progress: 0,
      lastAcknowledgedTime: Date.now(),
    };
    store.jobs = jobs;
    !job.ignoreSocketSubscribe &&
      $nuxt.$socket.emit("subscribe", `job.${job.id}`);
  },
  setBulkItemJobId(store, jobId) {
    store.bulkItemJobId = jobId;
  },
  setTask(store, task) {
    const tasks = {
      ...store.tasks,
    };
    tasks[task.id] = task;
    store.tasks = tasks;
    $nuxt.$socket.emit("subscribe", `task.${task.id}`);
  },

  setJobRetry(store, jobId) {
    const jobs = {
      ...store.jobs,
    };
    if (jobs[jobId].status === STATUS_TASK_DELETED) {
      return;
    }
    jobs[jobId].status = STATUS_REUPLOADING;
    jobs[jobId].statusText = STATUS_LABELS[STATUS_REUPLOADING];
    jobs[jobId].progress = 0;

    store.jobs = jobs;
  },
  setJobRetrying(store, jobId) {
    const jobs = {
      ...store.jobs,
    };
    jobs[jobId].status = STATUS_RETRYING;
    jobs[jobId].statusText = STATUS_LABELS[STATUS_RETRYING];
    jobs[jobId].progress = 0;
    jobs[jobId].eventOrder = 0;

    store.jobs = jobs;
  },
  setJobProgress(store, job) {
    const { jobs } = store;
    const { id, status, statusText, statusTitle, progress } = job;

    if (
      !id ||
      !jobs[id] ||
      [STATUS_TASK_DELETED, STATUS_ERROR].includes(jobs[id].status)
    ) {
      return;
    }

    if (status) {
      jobs[id].status = status;
    }

    if (statusText) {
      jobs[id].statusText = statusText;
    }

    jobs[id].statusTitle = statusTitle || "";

    if (!jobs[id].statusTitle && STATUS_LABELS[jobs[id].status]) {
      jobs[id].statusTitle = $nuxt.$t(STATUS_LABELS[jobs[id].status]);
    }

    if (progress !== undefined) {
      jobs[id].progress = Math.ceil(progress);
    }

    jobs[id].lastAcknowledgedTime = Date.now();
  },
  increaseTotalUploadedFileCount(store, jobId) {
    const { jobs } = store;

    if (!jobId || !jobs[jobId]) return;

    jobs[jobId].totalUploadedFile++;
  },
  processEvent(store, event) {
    const task = event.data;
    const tasks = {
      ...store.tasks,
    };
    const jobs = {
      ...store.jobs,
    };

    if (!tasks[task.id] || !tasks[task.id].job) {
      return;
    }

    /**
     * task update event have no details. only have id and progress, that why we need details from store
     */
    if (event.name === "items/taskUpdated") {
      task.job = tasks[task.id].job;
      task.operation = tasks[task.id].operation;
      task.status = "processing";
    }

    /**
     * some case task.job have job details and some case task.job have only id. That's why we need handle this
     */
    if (task.job && task.job.id) {
      task.job = task.job.id;
    }

    if (store.jobs[task.job]) {
      const eventOrderValue = getEventOrderValue(task.operation, task.status);
      if (
        jobs[task.job].eventOrder &&
        eventOrderValue < jobs[task.job].eventOrder
      ) {
        if (event.name === "items/taskCompleted") {
          $nuxt.$store.commit(event.name, event.data); // we must call this, due to unsubscribed socket task and also import file size update, based on this card https://freeconvert.atlassian.net/browse/FC-3923
        }
        return;
      }
      jobs[task.job].eventOrder = eventOrderValue;
      jobs[task.job].lastAcknowledgedTime = Date.now();
      store.jobs = jobs;
    }
    $nuxt.$store.commit(event.name, event.data);
  },
  startTask(store, task) {
    const tasks = {
      ...store.tasks,
    };
    if (tasks[task.id]) {
      tasks[task.id].status = TASK_PROCESSING;
      store.tasks = tasks;
    } else {
      return;
    }
    if (!task.job) {
      return;
    }
    if (task.job && task.job.id) {
      task.job = task.job.id;
    }

    const jobs = {
      ...store.jobs,
    };

    const errorCode = task?.result?.errorCode;

    if (tasks[task.id].operation.includes("export")) {
      jobs[task.job].statusText = STATUS_LABELS[STATUS_EXPORTING];
    } else if (jobs[task.job] && !tasks[task.id].operation.includes("import")) {
      jobs[task.job].status = STATUS_CONVERTING;
      jobs[task.job].statusText = getOperationName(tasks[task.id].operation);
      jobs[task.job].statusTitle = $nuxt.$t(STATUS_LABELS[STATUS_CONVERTING]);
    } else if (errorCode === USER_DELETED_TASK) {
      jobs[tasks[task.id].job].status = STATUS_TASK_DELETED;
      jobs[tasks[task.id].job].statusText = STATUS_LABELS[STATUS_TASK_DELETED];
      jobs[tasks[task.id].job].statusTitle = $nuxt.$t("user_deleted_task");
    }
    if (jobs[task.job]) {
      jobs[task.job].progress = 0;
      jobs[task.job].lastAcknowledgedTime = Date.now();
    }
    store.jobs = jobs;
  },
  taskCompleted(store, task) {
    if (task.job && task.job.id) {
      task.job = task.job.id;
    }
    const tasks = {
      ...store.tasks,
    };
    if (tasks[task.id]) {
      const jobs = {
        ...store.jobs,
      };
      if (jobs[tasks[task.id].job].statusTitle === "Job deleted") {
        return;
      }
      tasks[task.id].status = TASK_COMPLETED;
      store.tasks = tasks;
      if (tasks[task.id].operation.includes("import")) {
        jobs[tasks[task.id].job].inputSize = task.output.size;
        store.jobs = jobs;
      }
      if (tasks[task.id].operation.includes("export")) {
        // its  a dirty fix to change the download urls to CF proxy
        jobs[tasks[task.id].job].progress = 100;
        jobs[tasks[task.id].job].output = task.result.url || task.output.url;
        jobs[tasks[task.id].job].outputTaskId = task.id;
        jobs[tasks[task.id].job].outputSize = task.output.size;
        jobs[tasks[task.id].job].status = STATUS_DONE;
        jobs[tasks[task.id].job].statusText = STATUS_LABELS[STATUS_DONE];
        jobs[tasks[task.id].job].statusTitle = $nuxt.$t(
          STATUS_LABELS[STATUS_DONE]
        );
        store.jobs = jobs;
      }
    }
    $nuxt.$socket.emit("unsubscribe", `task.${task.id}`);
  },
  taskFailed(store, task) {
    if (task.job && task.job.id) task.job = task.job.id;
    const tasks = { ...store.tasks };
    const jobs = { ...store.jobs };

    if (!tasks[task.id]) return;
    if (jobs[tasks[task.id].job].status === STATUS_TASK_DELETED) return;

    if (task.type === "custom") {
      jobs[tasks[task.id].job].status = task.status;
      jobs[tasks[task.id].job].statusText = task.statusText;
      jobs[tasks[task.id].job].statusTitle = task.statusTitle;
      jobs[tasks[task.id].job].eventOrder = 15; // import + failed event order
      store.jobs = jobs;
      return;
    }

    if (jobs[tasks[task.id].job].statusTitle === "Job deleted") return;

    tasks[task.id].result = task.result;
    const oldStatus = jobs[tasks[task.id].job].status;
    const errorCode = task?.result?.errorCode;

    if (errorCode === OUT_OF_CONVERSION_MINUTES_PER_TASK) {
      tasks[task.id].status = STATUS_LIMIT_REACH_ERROR;
      jobs[tasks[task.id].job].status = STATUS_LIMIT_REACH_ERROR;
      jobs[tasks[task.id].job].statusText =
        STATUS_LABELS[STATUS_LIMIT_REACH_ERROR];
      let statusTitle = $nuxt.$t("out_of_conversion_minutes_per_task");
      statusTitle = statusTitle.replace(
        "LIMIT",
        $nuxt.$store.state.subscription.perTaskLimit
      );
      statusTitle = statusTitle.replace(
        "SUBSCRIPTION",
        $nuxt.$store.state.subscription.name
      );
      jobs[tasks[task.id].job].statusTitle = statusTitle;
      return;
    } else if (errorCode === OUT_OF_CONVERSION_MINUTES) {
      tasks[task.id].status = STATUS_LIMIT_REACH_ERROR;
      if (oldStatus < STATUS_DONE) {
        jobs[tasks[task.id].job].status = STATUS_LIMIT_REACH_ERROR;
        jobs[tasks[task.id].job].statusText =
          STATUS_LABELS[STATUS_LIMIT_REACH_ERROR];
        jobs[tasks[task.id].job].statusTitle = $nuxt.$t(
          "out_of_conversion_minutes"
        );
      }
    } else if (errorCode === USER_DELETED_TASK) {
      jobs[tasks[task.id].job].status = STATUS_TASK_DELETED;
      jobs[tasks[task.id].job].statusText = STATUS_LABELS[STATUS_TASK_DELETED];
      jobs[tasks[task.id].job].statusTitle = $nuxt.$t("user_deleted_task");
    } else if (errorCode === ENGINE_TIMEOUT) {
      jobs[tasks[task.id].job].status = STATUS_ERROR;
      jobs[tasks[task.id].job].statusText = STATUS_LABELS[STATUS_ERROR];
      jobs[tasks[task.id].job].statusTitle = $nuxt.$t(
        STATUS_LABELS[STATUS_SERVER_ERROR]
      );
      $nuxt.$gtm.push({ event: "server_error" });
    } else if (errorCode === UPLOAD_TIMEOUT) {
      jobs[tasks[task.id].job].status = STATUS_ERROR;
      jobs[tasks[task.id].job].statusText = STATUS_LABELS[STATUS_ERROR];
      jobs[tasks[task.id].job].statusTitle = $nuxt.$t(
        STATUS_LABELS[STATUS_UPLOAD_TIMEOUT]
      );
      $nuxt.$gtm.push({ event: "server_error" });
    } else if (errorCode === DRM_PROTECTED_FILE) {
      jobs[tasks[task.id].job].status = STATUS_ERROR;
      jobs[tasks[task.id].job].statusText = STATUS_LABELS[STATUS_ERROR];
      jobs[tasks[task.id].job].statusTitle = $nuxt.$t(
        STATUS_LABELS[STATUS_DRM_PROTECTED_FILE_ERROR]
      );
    } else {
      tasks[task.id].status = TASK_FAILED;
      if (oldStatus < STATUS_DONE) {
        jobs[tasks[task.id].job].status = STATUS_ERROR;
        jobs[tasks[task.id].job].statusText = STATUS_LABELS[STATUS_ERROR];
      }
      if (task.result?.msg) {
        jobs[tasks[task.id].job].statusTitle = $nuxt.$t(task.result.msg);
      }
    }

    store.tasks = tasks;
    store.jobs = jobs;
  },
  taskUpdated(store, task) {
    const tasks = {
      ...store.tasks,
    };

    const jobs = {
      ...store.jobs,
    };

    if (!tasks[task.id]) {
      return;
    }

    if (jobs[tasks[task.id].job].status === STATUS_TASK_DELETED) {
      return;
    }
    if (
      tasks[task.id].job &&
      jobs[tasks[task.id].job].status < STATUS_DONE &&
      tasks[task.id]
    ) {
      jobs[tasks[task.id].job].statusText = getOperationName(
        tasks[task.id].operation
      );
      if (!jobs[tasks[task.id].job].progress) {
        jobs[tasks[task.id].job].progress = Math.ceil(task.payload.progress);
      } else {
        jobs[tasks[task.id].job].progress =
          Math.ceil(task.payload.progress) > jobs[tasks[task.id].job].progress
            ? Math.ceil(task.payload.progress)
            : jobs[tasks[task.id].job].progress;
      }
      if (task.payload.fileSize) {
        jobs[tasks[task.id].job].fileSize = task.payload.fileSize;
      }
      if (task.payload.totalUpload) {
        jobs[tasks[task.id].job].totalUpload = task.payload.totalUpload;
      }
      if (task.payload.expectedFinishTime) {
        jobs[tasks[task.id].job].expectedFinishTime =
          task.payload.expectedFinishTime;
      }
      if (task.payload.uploadSpeed) {
        jobs[tasks[task.id].job].uploadSpeed = task.payload.uploadSpeed;
      }

      store.jobs = jobs;
    }
  },
  jobDeleted(store, job) {
    const jobs = {
      ...store.jobs,
    };
    if (jobs[job.id]) {
      jobs[job.id].status = STATUS_ERROR;
      jobs[job.id].statusText = STATUS_LABELS[STATUS_ERROR];
      jobs[job.id].statusTitle = $nuxt.$t("job_deleted");
      store.jobs = jobs;
    }
  },
  taskDeleted(store, task) {
    if (task.job && task.job.id) {
      task.job = task.job.id;
    }
    const tasks = {
      ...store.tasks,
    };
    if (tasks[task.id]) {
      const jobs = {
        ...store.jobs,
      };
      const errorCode = task?.result?.errorCode;
      if (errorCode === USER_DELETED_TASK) {
        jobs[tasks[task.id].job].status = STATUS_TASK_DELETED;
        jobs[tasks[task.id].job].statusText =
          STATUS_LABELS[STATUS_TASK_DELETED];
        jobs[tasks[task.id].job].statusTitle = $nuxt.$t("user_deleted_task");
      } else if (errorCode === ENGINE_TIMEOUT) {
        jobs[tasks[task.id].job].status = STATUS_ERROR;
        jobs[tasks[task.id].job].statusText = STATUS_LABELS[STATUS_ERROR];
        jobs[tasks[task.id].job].statusTitle = $nuxt.$t(
          STATUS_LABELS[STATUS_SERVER_ERROR]
        );
        $nuxt.$gtm.push({ event: "server_error" });
      } else if (errorCode === UPLOAD_TIMEOUT) {
        jobs[tasks[task.id].job].status = STATUS_ERROR;
        jobs[tasks[task.id].job].statusText = STATUS_LABELS[STATUS_ERROR];
        jobs[tasks[task.id].job].statusTitle = $nuxt.$t(
          STATUS_LABELS[STATUS_UPLOAD_TIMEOUT]
        );
        $nuxt.$gtm.push({ event: "server_error" });
      } else if (errorCode === DRM_PROTECTED_FILE) {
        jobs[tasks[task.id].job].status = STATUS_ERROR;
        jobs[tasks[task.id].job].statusText = STATUS_LABELS[STATUS_ERROR];
        jobs[tasks[task.id].job].statusTitle = $nuxt.$t(
          STATUS_LABELS[STATUS_DRM_PROTECTED_FILE_ERROR]
        );
      }
      store.tasks = tasks;
      store.jobs = jobs;
    }
  },
};
