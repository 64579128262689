/* eslint-disable no-undef */

import { getSettings } from '~/fc/advancedOptionHelper';
import { getKeyByValue } from '~/fc/helpers';
import { MERGE_OPTION_IDS } from '~/config/config'
import { fetchOptionsForExt, fetchAdvancedOptionsForExt, fetchSupportedTargets } from '~/fc/Dal/OptionsRepo';
import UserPrivilegesValidator from "~/fc/UserPrivilegesValidator"

const defaultUserData = {
  allSelection: {},
  fieldNames: {},
  conversionType: {},
  defaultAllowed: [],
  defaultHidden: [],
};
export const state = () => ({
  merging: false,
  sorting: false,
  attachments: {},
  cropAspectRatio: false,
  perConversionType: {},
  userData: { ...defaultUserData },
  showOnlyRootSettings: false,
  targetForExt: {},
  targetObjects: {},
  typeForExt: {},
  defaultTarget: '',
  targetForFile: {},
  cropSettingsIds: {},
  oldSelection: {
    allSelection: {},
  },
  advancedOptionsLoading: false,
  supportedTargets: null,
  mergeInfo: null,
  customDimensionActiveInput: false,
  selectedPreviewItem: null
});
export const mutations = {
  setInitialUserData(state, key) {
    const userData = { ...state.userData };
    const perConversionSelection = {
      ...state.perConversionType[key].selection,
    };
    if (Object.keys(perConversionSelection).length > 0) {
      userData.allSelection.root = perConversionSelection;
      /**
       * Checking wheather if merge is enabled while setting
       * the initial selections and if the root selections
       * have the merge options. Setting the merge option
       * true if merge is enabled.
       */
      if (state.merging) {
        for (let i = 0; i < MERGE_OPTION_IDS.length; i++) {
          if (MERGE_OPTION_IDS[i] in userData.allSelection.root) userData.allSelection.root[MERGE_OPTION_IDS[i]] = true
        }
      }
      userData.allSelection.root[MERGE_OPTION_IDS]
    }

    userData.fieldNames = {
      ...userData.fieldNames,
      ...state.perConversionType[key].fieldNames,
    };
    userData.conversionType.root = key;
    userData.conversionType.page = key;
    state.userData = userData;
  },
  setUserData(state, newData) {
    state.userData = newData;
  },
  setSelection(state, data) {
    const userData = { ...JSON.parse(JSON.stringify(state.userData)) };
    const info = $nuxt.$store.state.info
    const previewEnabled = (info.active === "PreviewTemplate") || (info.customVariables ? info.customVariables.enablePreview : false)
    let key;
    if (data.key) {
      key = data.key;
    } else if (data.name) {
      key = getKeyByValue(userData.fieldNames, data.name);
    } else {
      return;
    }
    // if it's root settings before file update, update page settings also
    if (data.uid === 'root' && $nuxt.$store.state.list.length === 0)
      userData.allSelection['page'][key] = data.value;
    // if it's page settings, update root also
    if (data.uid === "page" && userData.allSelection["root"]) userData.allSelection["root"][key] = data.value;
    if (userData.allSelection[data.uid]) userData.allSelection[data.uid][key] = data.value;
    state.userData = userData;

    if (data.uid === "root" && data.fromUpdate && $nuxt.$store.state.list.length && previewEnabled)
      this.commit('advancedSetting/copySelectionFromRootToAll')
  },
  setOldSelection(state, id) {
    state.oldSelection.allSelection[id] = {
      ...state.userData.allSelection[id],
    };
  },
  resetSelectionToRoot (state, {id, slug}) {
    state.userData.allSelection[id] = state.userData.allSelection.root
  },
  restoreOldSelection(state, id) {
    state.userData.allSelection[id] = {
      ...state.oldSelection.allSelection[id],
    };
  },
  setAllSelection(state, { id, options }) {
    const userData = { ...state.userData };
    userData.allSelection[id] = { ...options };
    state.userData = userData;
  },
  copySelection(state, { from, to }) {
    const sourceSettings = { ...state.userData.allSelection[from] };
    const fileSettings = { ...state.userData.allSelection[to] };
    state.userData.allSelection[to] = Object.assign(
      fileSettings,
      sourceSettings
    );
  },
  copySelectionFromObject(state, { from, to }) {
    const fileSettings = { ...state.userData.allSelection[to] };
    state.userData.allSelection[to] = Object.assign(
      fileSettings,
      from
    )
  },
  copySelectionFromRootToAll(state) {
    const { allSelection } = { ...state.userData }

    Object.keys(allSelection).forEach((id) => {
      if (
        (id !== 'root' && id !== 'page')
      ) {
        this.commit('advancedSetting/copySelection', {
          from: 'root',
          to: id
        })
      }
    })
  },
  initialCopySelection(state, { from, to, slug }) {
    const pageSettings = state.userData.allSelection.page;
    const perConversionSelection =
      slug && state.perConversionType[slug]
        ? {
          ...state.perConversionType[slug].selection,
        }
        : null;
    let sourceSettings =
      perConversionSelection && Object.keys(perConversionSelection).length > 0
        ? perConversionSelection
        : state.userData.allSelection[from];
    let fileSettings = pageSettings ? pageSettings : {};
    if (!sourceSettings) return;
    if (Object.keys(sourceSettings).length > 0) {
      for (let [prop, value] of Object.entries(sourceSettings)) {
        fileSettings[prop] =
          pageSettings && pageSettings[prop] !== undefined
            ? pageSettings[prop]
            : value;
      }
    }

    state.userData.allSelection[to] = fileSettings;
  },
  setSelectionForAllFiles (state, {option_id, value}) {
    for (const childObjectKey in state.userData.allSelection) {
      if (state.userData.allSelection.hasOwnProperty(childObjectKey)) {
          const childObject = state.userData.allSelection[childObjectKey];
          if (childObject.hasOwnProperty(option_id)) {
            state.userData.allSelection[childObjectKey][option_id] = value
          }
      }
    }
  },
  setSelectionForRoot (state, {option_id, value}) {
    state.userData.allSelection['root'][option_id] = value
  },
  copyMergeSelection(state, { merge, to }) {
    const d = { ...state.perConversionType };
    state.userData.allSelection[to] = { ...d[merge].selection };
  },
  copyConversionType(state, { from, to }) {
    state.userData.conversionType[to] = state.userData.conversionType[from];
  },
  setConversionType(state, { key, type }) {
    state.userData.conversionType[key] = type;
  },
  resetSelection(state, id) {
    if (state.perConversionType[state.userData.conversionType[id]]) {
      const userData = { ...JSON.parse(JSON.stringify(state.userData)) };
      userData.allSelection[id] = {
        ...state.perConversionType[userData.conversionType[id]].selection,
      };

      state.userData = userData;
    }
  },
  clearOptions(state) {
    state.merging = false;
    state.sorting = false;
    state.showOnlyRootSettings = false;
    state.userData = { ...defaultUserData };
    state.options = {};
    state.perConversionType = {};
    state.targetForFile = {};
    state.targetForExt = {};
    state.typeForExt = {};
    state.targetObjects = {};
    state.customDimensionActiveInput = false;
    state.oldSelection = {
      allSelection: {},
    };
  },
  setSupportedTargets(store, data) {
    store.supportedTargets = data;
  },
  setMergeInfo(store, data) {
    store.mergeInfo = data;
  },
  setDefaultTarget(store, data) {
    store.defaultTarget = data;
  },
  setCropAspectRatio(store, data) {
    store.cropAspectRatio = data;
  },
  setMerging(store, value) {
    store.merging = value;
  },
  setSort(store, value) {
    store.sorting = value;
  },
  setRootSetting(store, value) {
    store.showOnlyRootSettings = value;
  },
  setTarget(store, data) {
    const d = { ...store.targetForExt };

    d[data.key] = data.value;
    store.targetForExt = d;
    store.targetObjects = d
      ? Object.values(d)
        .map((x) => (x ? Object.values(x).flat() : []))
        .flat()
        .reduce((a, v) => ({ ...a, [v.slug]: v }), {})
      : [];
  },
  setExtType(store, data) {
    const d = { ...store.typeForExt };

    d[data.key] = data.value;
    store.typeForExt = d;
  },
  setPageSelections(state, data) {
    const d = { ...state.perConversionType };
    const userData = { ...state.userData };
    userData.allSelection.page = { ...d[data.key].selection };
  },
  setPerConversionType(state, data) {
    const d = { ...state.perConversionType };
    const userData = { ...state.userData };
    d[data.key] = getSettings(data.value);
    if (Object.keys(d[data.key].selection).length > 0) {
      userData.allSelection.root = { ...d[data.key].selection };
    }
    for (let key in userData.conversionType) {
      if (userData.conversionType[key] === data.key) {
        userData.allSelection[key] = { ...d[data.key].selection };
      }
    }
    userData.fieldNames = { ...userData.fieldNames, ...d[data.key].fieldNames };
    userData.conversionType.root = data.key;
    state.perConversionType = d;
    state.userData = userData;
  },
  setTargetForFile(
    store,
    { uid, ext, name, slug, device, type, targetType, ready }
  ) {
    const d = { ...store.targetForFile };
    if (ext) {
      d[uid] = {
        ext,
        name: name || ext,
        type,
        device,
        slug,
        targetType: targetType || type,
        ready,
      };
    } else {
      d[uid] = { ext: '', name: '', type: '', targetType: '', ready };
    }
    store.targetForFile = d;
  },
  setTypeForFile(store, { file, type }) {
    $nuxt.$store.commit('setTypeForFile', { file, type });
  },
  setAdvancedOptionsLoading(state, value) {
    state.advancedOptionsLoading = value
  },
  removeTargetForFile(store, fileId) {
    delete store.targetForFile[fileId];
  },
  setCropSettingsIds (state, {id, name}) {
    state.cropSettingsIds[name] = id;
  },
  setCustomDimensionActiveInput(state, value) {
    state.customDimensionActiveInput = value;
  },
  setSelectedPreviewItem(state, value) {
    state.selectedPreviewItem = value;
  }
};
export const actions = {
  async setTargetForExt ({ commit, state }, { ext }) {
    if (state.targetForExt[ext]) return Promise.resolve();
    return new Promise(async (resolve, reject) => {
      try {
        const infoOperation = $nuxt.$store.state.info.operation
        const operation = infoOperation && ['convert', 'compress', 'merge'].includes(infoOperation) ? infoOperation : 'convert'
        const supportedTargets = state.supportedTargets && state.supportedTargets[operation] && state.supportedTargets[operation][ext] 
          ?  JSON.parse(JSON.stringify(state.supportedTargets[operation][ext])) : null

        let targets = null

        if(supportedTargets) {
          targets = supportedTargets
        } else {
          const data = await fetchOptionsForExt(ext);
          targets = data.targets
        }
        commit('setTarget', { key: ext, value: targets });
        resolve();
      } catch (e) {
        reject();
      }
    });
  },
  async assignSupportedTargets({ commit, state }) {
    return new Promise(async (resolve, reject) => {
      try {
        const {data} = await fetchSupportedTargets();
        commit('setSupportedTargets', data)
        commit('setMergeInfo', data.mergeInfo)
        resolve();
      } catch (e) {
        console.error(e.message) // we'll catch it on Sentry
        reject();
      }
    });
  },
  async setTarget({ commit, state }, { ext }) {
    if (state.targetForExt[ext]) return Promise.resolve();
    const infoOperation = $nuxt.$store.state.info.operation
    const operation = infoOperation && ['convert', 'compress', 'merge'].includes(infoOperation) ? infoOperation : 'convert'
    return new Promise(async (resolve, reject) => {
      try {
        let data = null
        const supportedTargets = state.supportedTargets && state.supportedTargets[operation] && state.supportedTargets[operation][ext] 
          ?  JSON.parse(JSON.stringify(state.supportedTargets[operation][ext])) : null
        /**
         * In case, all the supported target API
         * is not yet done loading, we will fetch the 
         * target of this extension with the individual
         * targets API call. But this should be a very 
         * rare occurrence.
         */
        
        if(supportedTargets) {
          data = supportedTargets
        } else {
          data = await fetchOptionsForExt(ext)
          const _supportedTargets = {}
          _supportedTargets[operation][ext] = data
          commit('setSupportedTargets', _supportedTargets)
        }
        const { type, targets } = data;
        
        commit('setTarget', { key: ext, value: targets });
        commit('setExtType', { key: ext, value: type });
        resolve();
      } catch (e) {
        commit('setTarget', { key: ext, value: [] })
        resolve();
      }
    });
  },
  async setOptions(
    { commit, state },
    { slug, srcType, srcExt, targetType, targetExt, list, operation = 'convert' }
  ) {
    if (!slug && !targetExt) return;
    const key = slug || `${srcExt ? srcExt + '-' : ''}to-${targetExt}`;
    if (state.perConversionType[key]) {
      commit('setInitialUserData', key);
      return;
    }
    if (list !== undefined) {
      commit('setPerConversionType', { key, value: list });
      return;
    }
    commit('setPerConversionType', { key, value: 'loading'});
    if (!targetExt) return;
    if (srcType === 'multi') srcType = targetType;
    try {
      const data = await fetchAdvancedOptionsForExt(operation, srcExt, targetExt)
      if (data.options && operation === 'merge') $nuxt.$store.commit('setPageOptions', data.options);
      commit('setPerConversionType', { key, value: data && data.options ? data.options : false });
    } catch (err) {
      commit('setPerConversionType', { key, value: false });
    }
  },
  async setMerge ({ commit, state }, {slug, targetExt}) {
    commit('setMerging', slug)
    const userPrivilegesValidator =  new UserPrivilegesValidator($nuxt)
    userPrivilegesValidator.validateMergePrivilege()
    if (state.perConversionType[slug]) {
      commit('copySelectionFromObject', {from: await state.perConversionType[slug].selection, to: 'root'})
      return
    }
    try {
      const oldRootSettings = state.userData.allSelection.root
      commit('setPerConversionType', { key: slug, value: 'loading'});
      $nuxt.$store.commit('setPageOptions', 'loading');

      commit('setAdvancedOptionsLoading', true)

      const locale = $nuxt.$store.$i18n.locale;
      const url = `${process.env.QUERY_SERVICE}/view/options?`;
      const urlParams = [
        'include=options',
        `operation=merge`,
        `output_format=${targetExt.toLowerCase()}`,
        `lang=${locale}`,
      ];
      const { data } = await this.$axios.get(url + urlParams.join('&'));
      $nuxt.$store.commit('setPageOptions', data.options);
      commit('setPerConversionType', { key: slug, value: data && data.options ? data.options : false });
      
      commit('copySelectionFromObject', {from: oldRootSettings, to: 'root'})
      commit('setAdvancedOptionsLoading', false)
    } catch (e) {
      commit('setMerging', false)
      userPrivilegesValidator.validateMergePrivilege()
      commit('setPerConversionType', { key: slug, value: false });
      commit('setAdvancedOptionsLoading', false)
    }
  },
  async disableMerge ({ commit, state, rootState }, {slug}) {
    commit('setMerging', false)
    new UserPrivilegesValidator($nuxt).validateMergePrivilege()
  },
  setDefaultTarget({ commit, state, rootState }, target) {
    commit('setDefaultTarget', target);
  },
};
